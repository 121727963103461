import { useEffect } from 'react';
import { RepositoryFactory } from '@/apiRepository/RepositoryFactory';
const UploadImageRepository = RepositoryFactory.get('uploadImage');

const utils = {
  nFormatter(value, type = 'none') {
    if (value == 0) return '--';
    if (!value) return 'NA';
    if (value >= 1000000000) {
      return (value / 1000000000).toFixed(1).replace(/\.0$/, '') + 'b';
    }
    if (value >= 1000000) {
      return (value / 1000000).toFixed(1).replace(/\.0$/, '') + 'm';
    }
    if (value >= 1000) {
      return (value / 1000).toFixed(1).replace(/\.0$/, '') + 'k';
    }
    if (value < 1000) {
      if (type == 'decimal' && value < 100) return value.toFixed(2);
      return Math.round(value);
    }
    return value;
  },
  useOutsideAlerter(ref, handler) {
    useEffect(() => {
      function handleClickOutside(event) {
        if (ref.current && !ref.current.contains(event.target)) {
          handler();
        }
      }
      document.addEventListener('mousedown', handleClickOutside);
      return () => {
        document.removeEventListener('mousedown', handleClickOutside);
      };
    }, [ref]);
  },
  replaceAll(str, find, replace) {
    const escapeRegExp = (string) => {
      return string.replace(/[.*+?^${}()|[\]\\]/g, '\\$&'); // $& means the whole matched string
    };

    return str.replace(new RegExp(escapeRegExp(find), 'g'), replace);
  },
  commaFormatter(num) {
    if (num && parseFloat(num)) {
      var parts = num.toString().split('.');
      parts[0] = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, ',');
      if (parts[1] && parts[1].length > 0 && parseFloat(parts[1])) {
        parts[1] = parseFloat(`0.${parts[1]}`).toFixed(2);
        parts[1] = parts[1].toString().split('.')[1];
      }
      return parts.join('.');
    } else {
      return num;
    }
  },
  sortAndGroupStrings(categories) {
    let tempFormattedCategory = [];
    let sortedCategories = JSON.parse(JSON.stringify(categories)).sort((a, b) =>
      a.title[0].localeCompare(b.title[0])
    );
    let keyIndex = {};
    sortedCategories.forEach((category) => {
      let firstChar = category.title[0].toUpperCase();
      if (firstChar in keyIndex) {
        tempFormattedCategory[keyIndex[firstChar]].list.push(category);
      } else {
        let newIndex = tempFormattedCategory.length;
        keyIndex[firstChar] = newIndex;
        tempFormattedCategory.push({ char: firstChar, list: [category] });
      }
    });
    return tempFormattedCategory;
  },
  copyToClipboard(text) {
    // const input = document.createElement('input');
    // input.setAttribute('value', text);
    // document.body.appendChild(input);
    // input.select();
    // const result = document.execCommand('copy');
    // document.body.removeChild(input);
    // return result;
    navigator.clipboard.writeText(text);
  },
  utmToString(utm, separator = ', ') {
    console.log('reach', utm);
    if (!utm) return '';
    const string = Object.entries(utm)
      .filter(([, value]) => Boolean(value))
      .map(
        ([key, value]) =>
          `UTM ${key.charAt(0).toUpperCase() + key.slice(1)}: ${value}`
      )
      .join(separator);

    return string;
  },
  async uploadOnUrl(file, input) {
    const { data } = await UploadImageRepository.getPresignedUrl(input);
    await fetch(data.init.url, {
      method: 'PUT',
      headers: {
        'Content-Type': 'multipart/form-data',
      },
      body: file,
    });
    const finshData = await UploadImageRepository.finishUpload({
      path: data.init.path,
    });
    console.log({ finshData });
    return finshData;
  },
  getModuleValue(sourceCTA) {
    let val = '';
    if (sourceCTA === 'Home') val = 'Home Page';
    else if (sourceCTA === 'Featured_Influencers') val = 'Featured Influencers';
    else if (sourceCTA === 'Recent_Activity') val = 'Recent Activity';
    else if (sourceCTA === 'Header_Search_Suggestion')
      val = 'Header Search Suggestion';
    else if (sourceCTA === 'Discovery') val = 'Creator Discovery';
    else if (sourceCTA === 'Genre') val = 'Genre Insights';
    else if (sourceCTA === 'Influencer_Report') val = 'Influencer Report';
    else if (sourceCTA === 'Leaderboard') val = 'Leaderboard';
    else if (sourceCTA === 'Collections') val = 'Collections';
    else if (sourceCTA === 'Content_Discovery') val = 'Content Discovery';
    else if (sourceCTA === 'Nav_Bar') val = 'Nav Bar';
    else if (sourceCTA === 'Campaign_Report') val = 'Campaign Reporting';
    else if (sourceCTA === 'Account_Tracking') val = 'Account Tracking';
    else if (sourceCTA === 'Smart_Campaigns') val = 'Smart Campaigns';
    else if (sourceCTA === 'Onboarding_Requirement')
      val = 'Onboarding Requirement';
    return val;
  },
  getModuleFromUrlPath() {
    let href = window.location.href;
    let pathname = window.location.pathname;
    let val = '';

    if (
      pathname.includes('/sso') &&
      new URL(href).searchParams.has('redirectUrl')
    ) {
      const redirectUrl = new URL(href).searchParams.get('redirectUrl');
      href = redirectUrl;
      pathname = new URL(redirectUrl).pathname;
    }

    if (pathname.includes('/smart-campaigns')) val = 'smart-campaigns';
    else if (pathname.includes('/discovery')) val = 'creator-discovery';
    else if (pathname.includes('/offline-campaign-reports'))
      val = 'campaign-reporting';
    else if (pathname.includes('/account-tracking')) val = 'account-tracking';
    else if (pathname.includes('/leaderboard')) val = 'leaderboard';
    else if (pathname.includes('/genre')) val = 'genre-insights';
    else if (pathname.includes('/topic_research')) val = 'topic_research';
    else if (pathname.includes('/influencer')) val = 'influencer-profile';
    else if (pathname.includes('/collections')) val = 'collections';
    else if (pathname === '/') val = 'home';

    return val || pathname;
  },
  extractIgYtCode(url) {
    //https://www.instagram.com/virat.kohli/
    //https://www.youtube.com/channel/UC5jBPeOfRBfV03wplqJAR9g
    //https://www.youtube.com/@tseries
    //virat.kohli, tseries, UC5jBPeOfRBfV03wplqJAR9g
    let result = url;
    if (url.includes('instagram.com')) {
      let test = url.match(/instagram\.com\/([^/]+)/);
      result = test ? test[1] : null;
    } else if (url.includes('youtube.com')) {
      let test = url.match(/youtube\.com\/channel\/([A-Za-z0-9_-]+)/i);
      result = test ? test[1] : null;
      if (!test) {
        test = url.match(/youtube\.com\/@([^/?]+)/i);
        result = test ? '@' + test[1] : null;
      }
    }
    return result ? result : url;
  },
};

export const {
  nFormatter,
  useOutsideAlerter,
  replaceAll,
  commaFormatter,
  copyToClipboard,
  utmToString,
  uploadOnUrl,
  getModuleValue,
  getModuleFromUrlPath,
  extractIgYtCode,
} = utils;
