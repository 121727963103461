import WebengageEvents from 'service/WebengageService';
import moment from 'moment';

const languageMapper = {
  hi: 'Hindi',
  en: 'English',
  ta: 'Tamil',
  bn: 'Bengali',
  te: 'Telugu',
  ml: 'Malyalam',
  mr: 'Marathi',
  gu: 'Gujarati',
  pa: 'Punjabi',
  or: 'Odia',
  ka: 'Kannada',
};

const getUtm = () => {
  let utm = {};
  const utmParameters = window.sessionStorage.getItem('utmParameters');
  if (window.sessionStorage.getItem('adsSource'))
    utm.campaign = window.sessionStorage.getItem('adsSource');
  else if (utmParameters) {
    utm = JSON.parse(utmParameters);
  }
  return utm;
};

const getPartnerId = () => {
  if (window.sessionStorage.getItem('brandId'))
    return window.sessionStorage.getItem('brandId');
  else return '';
};
/* eslint-disable no-undef */
const general = {
  initWebengageUser(payload = {}) {
    webengage.user.setAttribute('we_email', payload.email);
    webengage.user.setAttribute('we_phone', '+91 ' + payload.phone);
    webengage.user.setAttribute('we_first_name', payload.name);

    if (payload.webengageUserId) webengage.user.login(payload.webengageUserId);
  },
  async wClickTracking(eventName, payload = {}, type = ['track']) {
    let utm = {};
    const utmParameters = window.sessionStorage.getItem('utmParameters');
    if (window.sessionStorage.getItem('adsSource'))
      utm.campaign = window.sessionStorage.getItem('adsSource');
    else if (utmParameters) {
      utm = JSON.parse(utmParameters);
    }

    let userId, email;
    if (typeof window !== 'undefined' && Cookies.get('user')) {
      // eslint-disable-next-line no-undef
      const cookie = JSON.parse(Cookies.get('user'));
      userId =
        (cookie && cookie.userAccount && cookie.userAccount.webengageUserId) ||
        '';
      email = (cookie && cookie.userAccount && cookie.userAccount.email) || '';
    }

    let event = {
      data: null,
      type: '',
    };

    if (
      window.sessionStorage.getItem('brandId') != -1 ||
      window.localStorage.getItem('webengageDebug') ||
      process.env.NEXT_PUBLIC_APP_ENV === 'local'
    ) {
      if (type.includes('lens')) {
        event.type = 'lens';
        event.data = {
          userId: userId || '',
          anonymousId: window.localStorage.getItem('deviceId') || null,
          eventName: eventName || '',
          eventTime: String(moment().valueOf()),
          appType: 'BRAND',
          eventData: {
            Email: email,
            ...payload,
          },
        };
        try {
          const webengageData = await WebengageEvents.webengageEventHandler(
            event.data
          );
          console.log({ webengageEvent: webengageData });
        } catch (e) {
          console.error({ webengageEvent: e });
        }
      } else if (type.includes('track')) {
        event.type = 'track';
        event.data = {
          Source: utm.source || '',
          'Device ID': window.localStorage.getItem('deviceId') || '',
          'Ad source': utm.campaign || '',
          ...payload,
        };
        webengage.track(eventName, event.data);
      }
    } else if (type.includes('track')) {
      event.type = 'track';
      event.data = {
        Source: utm.source || '',
        'Device ID': window.localStorage.getItem('deviceId') || '',
        'Ad source': utm.campaign || '',
        ...payload,
      };
      webengage.track(eventName, event.data);
    }

    console.log('event triggered');
    if (
      window.localStorage.getItem('webengageDebug') ||
      process.env.NEXT_PUBLIC_APP_ENV === 'local'
    ) {
      console.log(eventName);
      console.log(event);
    }

    if (
      window.localStorage.getItem('webengageDebug') ||
      process.env.NEXT_PUBLIC_APP_ENV === 'local'
    ) {
      console.debug('event triggered');
      console.debug(eventName);
      console.debug(event);
    }
  },
  homePageVisited(payload) {
    let utm = {};
    const utmParameters = window.sessionStorage.getItem('utmParameters');
    if (window.sessionStorage.getItem('adsSource'))
      utm.campaign = window.sessionStorage.getItem('adsSource');
    else if (utmParameters) {
      utm = JSON.parse(utmParameters);
    }
    general.wClickTracking(
      'VS - Home page visited',
      {
        Source: utm.source || '',
        'Device ID': window.localStorage.getItem('deviceId') || '',
        'Ad source': utm.campaign || '',
        'User ID': payload.userId || '',
        'Plan type': payload.planType || '',
        'Partner ID': getPartnerId(),
        'Source CTA': payload.sourceCTA || '',
      },
      ['track', 'lens']
    );
  },
  homePageSearchUsed(payload) {
    let utm = {};
    const utmParameters = window.sessionStorage.getItem('utmParameters');
    if (window.sessionStorage.getItem('adsSource'))
      utm.campaign = window.sessionStorage.getItem('adsSource');
    else if (utmParameters) {
      utm = JSON.parse(utmParameters);
    }
    webengage.track('VS - Home page search used', {
      Source: utm.source || '',
      'Device ID': window.localStorage.getItem('deviceId') || '',
      'Ad source': utm.campaign || '',
      'User ID': payload.userId || '',
      'Plan type': payload.planType || '',
      Platform: payload.platform || '',
      'Search term': payload.searchTerm || '',
      'Partner ID': getPartnerId(),
    });
  },
  topNavSearchUsed(payload) {
    let utm = {};
    const utmParameters = window.sessionStorage.getItem('utmParameters');
    if (window.sessionStorage.getItem('adsSource'))
      utm.campaign = window.sessionStorage.getItem('adsSource');
    else if (utmParameters) {
      utm = JSON.parse(utmParameters);
    }
    webengage.track('VS - Top Nav search used', {
      Source: utm.source || '',
      'Device ID': window.localStorage.getItem('deviceId') || '',
      'Ad source': utm.campaign || '',
      'User ID': payload.userId || '',
      'Plan type': payload.planType || '',
      'Search term': payload.searchTerm || '',
      'Partner ID': getPartnerId(),
    });
  },
  featuredInfluencersFiltersChanged(payload) {
    let utm = {};
    const utmParameters = window.sessionStorage.getItem('utmParameters');
    if (window.sessionStorage.getItem('adsSource'))
      utm.campaign = window.sessionStorage.getItem('adsSource');
    else if (utmParameters) {
      utm = JSON.parse(utmParameters);
    }
    webengage.track('VS - Featured influencers filters changed', {
      Source: utm.source || '',
      'Device ID': window.localStorage.getItem('deviceId') || '',
      'Ad source': utm.campaign || '',
      'User ID': payload.userId || '',
      'Plan type': payload.planType || '',
      'Search term': payload.searchTerm || '',
      Platform: payload.platform || '',
      Category: payload.category || '',
      'Platform changed': payload.platformChanged || '',
      'Category changed': payload.categoryChanged || '',
      'Partner ID': getPartnerId(),
    });
  },
  homePageCreatorCardClicked(payload) {
    let utm = {};
    const utmParameters = window.sessionStorage.getItem('utmParameters');
    if (window.sessionStorage.getItem('adsSource'))
      utm.campaign = window.sessionStorage.getItem('adsSource');
    else if (utmParameters) {
      utm = JSON.parse(utmParameters);
    }
    webengage.track('VS - Home page creator card clicked', {
      Source: utm.source || '',
      'Device ID': window.localStorage.getItem('deviceId') || '',
      'Ad source': utm.campaign || '',
      'User ID': payload.userId || '',
      'Plan type': payload.planType || '',
      Platform: payload.platform || '',
      Category: payload.category || '',
      'Creator ID': payload.creatorId || '',
      'Partner ID': getPartnerId(),
    });
  },
  creatorDiscoveryPageVisited(payload) {
    let utm = {};
    const utmParameters = window.sessionStorage.getItem('utmParameters');
    if (window.sessionStorage.getItem('adsSource'))
      utm.campaign = window.sessionStorage.getItem('adsSource');
    else if (utmParameters) {
      utm = JSON.parse(utmParameters);
    }
    // TODO partner id, user id data
    general.wClickTracking(
      'VS - Creator Discovery page visited',
      {
        Source: utm.source || '',
        'Device ID': window.localStorage.getItem('deviceId') || '',
        'Ad source': utm.campaign || '',
        'User ID': payload.userId || '',
        'Plan type': payload.planType || '',
        'Partner ID': getPartnerId(),
        'Source CTA': payload.sourceCTA || '',
      },
      ['track', 'lens']
    );
  },
  creatorDiscoverySearchUsed(payload) {
    console.log({ payload });
    let utm = {};
    const utmParameters = window.sessionStorage.getItem('utmParameters');
    if (window.sessionStorage.getItem('adsSource'))
      utm.campaign = window.sessionStorage.getItem('adsSource');
    else if (utmParameters) {
      utm = JSON.parse(utmParameters);
    }
    webengage.track('VS - Creator Discovery search used', {
      Source: utm.source || '',
      'Device ID': window.localStorage.getItem('deviceId') || '',
      'Ad source': utm.campaign || '',
      'User ID': payload.userId || '',
      'Plan type': payload.planType || '',
      'Influencer size min': payload.influencerSizeMin || '',
      'Influencer size max': payload.influencerSizeMax || '',
      'Audience location': payload.audienceLocation || '',
      'Audience age min': payload.audienceAgeMin || '',
      'Audience age max': payload.audienceAgeMax || '',
      'Audience gender': payload.audienceGender || '',
      'Influencer location': payload.influencerLocation || '',
      'Influencer gender': payload.influencerGender || '',
      'Influencer languages': payload.influencerLanguages || '',
      'Influencer contact info': payload.influencerContactInfo || '',
      'Influencer categories': payload.influencerCategories || '',
      'Influencer keywords': payload.influencerKeywords || '',
      'Est. post price min': payload.estPostPriceMin || '',
      'Est. post price max': payload.estPostPriceMax || '',
      'Average likes min': payload.averageLikesMin || '',
      'Average likes max': payload.averageLikesMax || '',
      'Estimated reach min': payload.estimatedReachMin || '',
      'Estimated reach max': payload.estimatedReachMax || '',
      'Estimated impressions min': payload.estimatedImpressionsMin || '',
      'Estimated impressions max': payload.estimatedImpressionsMax || '',
      'Partner ID': getPartnerId(),
    });
  },
  leaderboardVisited(payload) {
    let utm = {};
    const utmParameters = window.sessionStorage.getItem('utmParameters');
    if (window.sessionStorage.getItem('adsSource'))
      utm.campaign = window.sessionStorage.getItem('adsSource');
    else if (utmParameters) {
      utm = JSON.parse(utmParameters);
    }
    webengage.track('VS - Leaderboard visited', {
      Source: utm.source || '',
      'Device ID': window.localStorage.getItem('deviceId') || '',
      'Ad source': utm.campaign || '',
      'User ID': payload.userId || '',
      'Plan type': payload.planType || '',
      Platform: payload.platform || '',
      Category: payload.category || '',
      'Sort by': payload.sortBy || '',
      Language: payload.language || '',
      'Time frame': payload.timeFrame || '',
      'Partner ID': getPartnerId(),
      'Source CTA': payload.sourceCTA || '',
    });
  },
  creatorAddedToCollection(payload) {
    console.log({ payload });
    let utm = {};
    const utmParameters = window.sessionStorage.getItem('utmParameters');
    if (window.sessionStorage.getItem('adsSource'))
      utm.campaign = window.sessionStorage.getItem('adsSource');
    else if (utmParameters) {
      utm = JSON.parse(utmParameters);
    }
    webengage.track('VS - Creator added to collection', {
      Source: utm.source || '',
      'Device ID': window.localStorage.getItem('deviceId') || '',
      'Ad source': utm.campaign || '',
      'User ID': payload.userId || '',
      'Plan type': payload.planType || '',
      Platform: payload.platform || '',
      'Collection ID': payload.collectionId || '',
      'Recommended collection': payload.recommendedCollection || '',
      'Partner ID': getPartnerId(),
    });
  },
  creatorCollectionCreated(payload) {
    console.log({ payload });
    let utm = {};
    const utmParameters = window.sessionStorage.getItem('utmParameters');
    if (window.sessionStorage.getItem('adsSource'))
      utm.campaign = window.sessionStorage.getItem('adsSource');
    else if (utmParameters) {
      utm = JSON.parse(utmParameters);
    }
    webengage.track('VS - Creator collection created', {
      Source: utm.source || '',
      'Device ID': window.localStorage.getItem('deviceId') || '',
      'Ad source': utm.campaign || '',
      'User ID': payload.userId || '',
      'Plan type': payload.planType || '',
      Category: payload.category || '',
      Tags: payload.tags || '',
      'Partner ID': getPartnerId(),
    });
  },
  creatorCollectionShared(payload) {
    console.log({ payload });
    let utm = {};
    const utmParameters = window.sessionStorage.getItem('utmParameters');
    if (window.sessionStorage.getItem('adsSource'))
      utm.campaign = window.sessionStorage.getItem('adsSource');
    else if (utmParameters) {
      utm = JSON.parse(utmParameters);
    }
    webengage.track('VS - Creator collection shared', {
      Source: utm.source || '',
      'Device ID': window.localStorage.getItem('deviceId') || '',
      'Ad source': utm.campaign || '',
      'User ID': payload.userId || '',
      'Plan type': payload.planType || '',
      Category: payload.category || '',
      Tags: payload.tags || '',
      'Collection ID': payload.collectionId || '',
      'Link Regenerated': payload.linkRegenerated || '',
      'Partner ID': getPartnerId(),
    });
  },
  collectionListingPage(payload) {
    console.log({ payload });
    let utm = {};
    const utmParameters = window.sessionStorage.getItem('utmParameters');
    if (window.sessionStorage.getItem('adsSource'))
      utm.campaign = window.sessionStorage.getItem('adsSource');
    else if (utmParameters) {
      utm = JSON.parse(utmParameters);
    }
    webengage.track('VS - Collection Listing Page', {
      Source: utm.source || '',
      'Device ID': window.localStorage.getItem('deviceId') || '',
      'Ad source': utm.campaign || '',
      'User ID': payload.userId || '',
      'Plan type': payload.planType || '',
      'Partner ID': getPartnerId(),
    });
  },
  collectionOpened(payload) {
    console.log({ payload });
    let utm = {};
    const utmParameters = window.sessionStorage.getItem('utmParameters');
    if (window.sessionStorage.getItem('adsSource'))
      utm.campaign = window.sessionStorage.getItem('adsSource');
    else if (utmParameters) {
      utm = JSON.parse(utmParameters);
    }
    webengage.track('VS - Collection Opened', {
      Source: utm.source || '',
      'Device ID': window.localStorage.getItem('deviceId') || '',
      'Ad source': utm.campaign || '',
      'User ID': payload.userId || '',
      'Plan type': payload.planType || '',
      Category: payload.category || '',
      Tags: payload.tags || '',
      'Collection ID': payload.collectionId || '',
      'Partner ID': getPartnerId(),
    });
  },
  collectionDetailsUpdated(payload) {
    console.log({ payload });
    let utm = {};
    const utmParameters = window.sessionStorage.getItem('utmParameters');
    if (window.sessionStorage.getItem('adsSource'))
      utm.campaign = window.sessionStorage.getItem('adsSource');
    else if (utmParameters) {
      utm = JSON.parse(utmParameters);
    }
    webengage.track('VS - Collection details updated', {
      Source: utm.source || '',
      'Device ID': window.localStorage.getItem('deviceId') || '',
      'Ad source': utm.campaign || '',
      'User ID': payload.userId || '',
      'Plan type': payload.planType || '',
      Category: payload.category || '',
      Tags: payload.tags || '',
      'Collection ID': payload.collectionId || '',
      'Partner ID': getPartnerId(),
    });
  },
  collectionDeleted(payload) {
    console.log({ payload });
    let utm = {};
    const utmParameters = window.sessionStorage.getItem('utmParameters');
    if (window.sessionStorage.getItem('adsSource'))
      utm.campaign = window.sessionStorage.getItem('adsSource');
    else if (utmParameters) {
      utm = JSON.parse(utmParameters);
    }
    webengage.track('VS - Collection deleted', {
      Source: utm.source || '',
      'Device ID': window.localStorage.getItem('deviceId') || '',
      'Ad source': utm.campaign || '',
      'User ID': payload.userId || '',
      'Plan type': payload.planType || '',
      Category: payload.category || '',
      Tags: payload.tags || '',
      'Collection ID': payload.collectionId || '',
      'Partner ID': getPartnerId(),
    });
  },
  collectionShare(payload) {
    console.log({ payload });
    let utm = {};
    const utmParameters = window.sessionStorage.getItem('utmParameters');
    if (window.sessionStorage.getItem('adsSource'))
      utm.campaign = window.sessionStorage.getItem('adsSource');
    else if (utmParameters) {
      utm = JSON.parse(utmParameters);
    }
    webengage.track('VS - Collection share', {
      Source: utm.source || '',
      'Device ID': window.localStorage.getItem('deviceId') || '',
      'Ad source': utm.campaign || '',
      'User ID': payload.userId || '',
      'Plan type': payload.planType || '',
      Category: payload.category || '',
      Tags: payload.tags || '',
      'Collection ID': payload.collectionId || '',
      'Partner ID': getPartnerId(),
    });
  },
  userSignUp(payload) {
    console.log({ payload });
    let utm = {};
    const utmParameters = window.sessionStorage.getItem('utmParameters');
    if (window.sessionStorage.getItem('adsSource'))
      utm.campaign = window.sessionStorage.getItem('adsSource');
    else if (utmParameters) {
      utm = JSON.parse(utmParameters);
    }

    if (payload.status == 'failed') {
      webengage.track('VS - User Sign Up Failed', {
        Source: utm.source || '',
        'Device ID': window.localStorage.getItem('deviceId') || '',
        'Ad source': utm.campaign || '',
        'User ID': payload.userId || '',
        'Plan type': payload.planType || '',
        'Partner ID': getPartnerId(),
        'Module Value': payload.moduleValue || '',
        'Full Name': payload.fullName || '',
        Email: payload.email || '',
      });
    } else {
      webengage.track('VS - User sign up', {
        Source: utm.source || '',
        'Device ID': window.localStorage.getItem('deviceId') || '',
        'Ad source': utm.campaign || '',
        'User ID': payload.userId || '',
        'Plan type': payload.planType || '',
        'Partner ID': getPartnerId(),
        'Module Value': payload.moduleValue || '',
      });
    }
  },
  userLogsIn(payload) {
    console.log({ payload });
    let utm = {};
    const utmParameters = window.sessionStorage.getItem('utmParameters');
    if (window.sessionStorage.getItem('adsSource'))
      utm.campaign = window.sessionStorage.getItem('adsSource');
    else if (utmParameters) {
      utm = JSON.parse(utmParameters);
    }
    webengage.track('VS - User logs in', {
      Source: utm.source || '',
      'Device ID': window.localStorage.getItem('deviceId') || '',
      'Ad source': utm.campaign || '',
      'User ID': payload.userId || '',
      'Plan type': payload.planType || '',
      'Partner ID': getPartnerId(),
    });
  },
  upgradePlanClicked(payload) {
    console.log({ payload });
    let utm = {};
    const utmParameters = window.sessionStorage.getItem('utmParameters');
    if (window.sessionStorage.getItem('adsSource'))
      utm.campaign = window.sessionStorage.getItem('adsSource');
    else if (utmParameters) {
      utm = JSON.parse(utmParameters);
    }
    webengage.track('VS - Upgrade plan clicked', {
      Source: utm.source || '',
      'Device ID': window.localStorage.getItem('deviceId') || '',
      'Ad source': utm.campaign || '',
      'User ID': payload.userId || '',
      'Plan type': payload.planType || '',
      'Partner ID': getPartnerId(),
    });
  },
  leadSubmitted(payload) {
    console.log({ payload });
    let utm = {};
    const utmParameters = window.sessionStorage.getItem('utmParameters');
    if (window.sessionStorage.getItem('adsSource'))
      utm.campaign = window.sessionStorage.getItem('adsSource');
    else if (utmParameters) {
      utm = JSON.parse(utmParameters);
    }
    webengage.track('VS - Lead submitted', {
      Source: utm.source || '',
      'Device ID': window.localStorage.getItem('deviceId') || '',
      'Ad source': utm.campaign || '',
      'User ID': payload.userId || '',
      'Plan type': payload.planType || '',
      Requirement: payload.requirement || '',
      'Partner ID': getPartnerId(),
    });
  },
  userLogOut(payload) {
    console.log({ payload });
    let utm = {};
    const utmParameters = window.sessionStorage.getItem('utmParameters');
    if (window.sessionStorage.getItem('adsSource'))
      utm.campaign = window.sessionStorage.getItem('adsSource');
    else if (utmParameters) {
      utm = JSON.parse(utmParameters);
    }
    webengage.track('VS - User Log out', {
      Source: utm.source || '',
      'Device ID': window.localStorage.getItem('deviceId') || '',
      'Ad source': utm.campaign || '',
      'User ID': payload.userId || '',
      'Plan type': payload.planType || '',
      'Partner ID': getPartnerId(),
    });
  },
  creatorProfileVisited(payload) {
    console.log({ payload });
    let utm = {};
    const utmParameters = window.sessionStorage.getItem('utmParameters');
    if (window.sessionStorage.getItem('adsSource'))
      utm.campaign = window.sessionStorage.getItem('adsSource');
    else if (utmParameters) {
      utm = JSON.parse(utmParameters);
    }
    webengage.track('VS - Creator profile visited', {
      Source: utm.source || '',
      'Device ID': window.localStorage.getItem('deviceId') || '',
      'Ad source': utm.campaign || '',
      'User ID': payload.userId || '',
      'Plan type': payload.planType || '',
      'Instagram handle': payload.instagramHandle || '',
      'Youtube channel ID': payload.youtubeChannelId || '',
      Categories: payload.categories || [],
      'Creator ID': payload.creatorId || '',
      'Partner ID': getPartnerId(),
      'Source CTA': payload.sourceCTA || '',
    });
  },
  creatorProfileShared(payload) {
    console.log({ payload });
    let utm = {};
    const utmParameters = window.sessionStorage.getItem('utmParameters');
    if (window.sessionStorage.getItem('adsSource'))
      utm.campaign = window.sessionStorage.getItem('adsSource');
    else if (utmParameters) {
      utm = JSON.parse(utmParameters);
    }
    webengage.track('VS - Creator profile shared', {
      Source: utm.source || '',
      'Device ID': window.localStorage.getItem('deviceId') || '',
      'Ad source': utm.campaign || '',
      'User ID': payload.userId || '',
      'Plan type': payload.planType || '',
      'Instagram handle': payload.instagramHandle || '',
      'Youtube channel ID': payload.youtubeChannelId || '',
      'Creator ID': payload.creatorId || '',
      'Partner ID': getPartnerId(),
    });
  },
  genreInsightVisited(payload) {
    const utm = getUtm();
    webengage.track('VS - Genre Insights Visited', {
      Source: utm.source || '',
      'Device ID': window.localStorage.getItem('deviceId') || '',
      'Ad source': utm.campaign || '',
      'User ID': payload.userId || '',
      'Plan type': payload.planType || '',
      'Partner ID': getPartnerId(),
      'Source CTA': payload.sourceCTA || '',
    });
  },
  genreUpgradeNow(payload) {
    const utm = getUtm();
    webengage.track('VS - Genre Insights - Upgrade Now Clicked', {
      Source: 'Genre Insights',
      'Device ID': window.localStorage.getItem('deviceId') || '',
      'Ad source': utm.campaign || '',
      'User ID': payload.userId || '',
      'Plan type': payload.planType || '',
      'Partner ID': getPartnerId(),
    });
  },
  genreFilterApplied(payload) {
    const utm = getUtm();

    webengage.track('VS - Genre Insights - Filter Applied', {
      Source: 'Genre Insights',
      'Device ID': window.localStorage.getItem('deviceId') || '',
      'Ad source': utm.campaign || '',
      'User ID': payload.userId || '',
      'Plan type': payload.planType || '',
      'Section Title': payload.sectionTitle || 'Genre Insights',
      Platform: payload.platform || 'ALL',
      'Publisher Type': payload.publisherType || 'ALL',
      Genre:
        payload.categories == 'All Categories'
          ? 'ALL'
          : payload.categories || 'ALL',
      Language:
        payload.language != 'ALL'
          ? languageMapper[payload.language]
          : 'ALL' || 'ALL',
      'Time Period': payload.time || '',
      'Sort By': payload.sortBy || '',
      Gender: payload.gender || '',
      'Age Range': payload.ageRange || '',
      'Partner ID': getPartnerId(),
    });
  },
  campaignReportVisited(payload) {
    const utm = getUtm();
    general.wClickTracking(
      'VS - Campaign reporting visited',
      {
        Source: utm.source || '',
        'Device ID': window.localStorage.getItem('deviceId') || '',
        'Ad source': utm.campaign || '',
        'User ID': payload.userId || '',
        'Plan type': payload.planType || '',
        'Reports generated': payload.reportsGenerated || '',
        'Partner ID': getPartnerId(),
        'Source CTA': payload.sourceCTA || '',
      },
      ['track', 'lens']
    );
  },
  campaignReportAddPost(payload) {
    const utm = getUtm();
    webengage.track('VS - Campaign Reporting - Add post clicked', {
      Source: utm.source || '',
      'Device ID': window.localStorage.getItem('deviceId') || '',
      'Ad source': utm.campaign || '',
      'User ID': payload.userId || '',
      'Plan type': payload.planType || '',
      'Reports generated': payload.reportsGenerated || '',
      'Partner ID': getPartnerId(),
    });
  },

  campaignReportPostAdded(payload) {
    const utm = getUtm();
    webengage.track('VS - Campaign Reporting - Post added', {
      Source: utm.source || '',
      'Device ID': window.localStorage.getItem('deviceId') || '',
      'Ad source': utm.campaign || '',
      'User ID': payload.userId || '',
      'Plan type': payload.planType || '',
      'Post upload type': payload.postUploadType || '',
      'Free reports': payload.freeReports,
      'Reports generated': payload.reportsGenerated || '',
      'Partner ID': getPartnerId(),
    });
  },

  campaignReportGenerated(payload) {
    const utm = getUtm();
    webengage.track('VS - Campaign Reporting - Report Generated', {
      Source: utm.source || '',
      'Device ID': window.localStorage.getItem('deviceId') || '',
      'Ad source': utm.campaign || '',
      'User ID': payload.userId || '',
      'Plan type': payload.planType || '',
      'Post upload type': payload.postUploadType || '',
      'Free reports': payload.freeReports,
      'Reports generated': payload.reportsGenerated || '',
      'Campaign name': payload.campaignName || '',
      'Campaign budget': payload.campaignBudget || '',
      'Partner ID': getPartnerId(),
    });
  },

  campaignReportUpgrade(payload) {
    const utm = getUtm();
    webengage.track('VS - Campaign Reporting - Upgrade Now Clicked', {
      Source: 'Campaign Reporting',
      'Device ID': window.localStorage.getItem('deviceId') || '',
      'Ad source': utm.campaign || '',
      'User ID': payload.userId || '',
      'Plan type': payload.planType || '',
      'Partner ID': getPartnerId(),
    });
  },
  smartCampaignsVisited(payload) {
    const utm = getUtm();
    general.wClickTracking(
      'VS - Smart Campaigns Visited',
      {
        Source: utm.source || '',
        'Device ID': window.localStorage.getItem('deviceId') || '',
        'Ad source': utm.campaign || '',
        'User ID': payload.userId || '',
        'Plan type': payload.planType || '',
        'Partner ID': getPartnerId(),
        'Source CTA': payload.sourceCTA || '',
      },
      ['track', 'lens']
    );
  },
  createSmartCampaignClicked(payload) {
    const utm = getUtm();
    webengage.track('VS - Create Smart Campaign Clicked', {
      Source: utm.source || '',
      'Device ID': window.localStorage.getItem('deviceId') || '',
      'Ad source': utm.campaign || '',
      'User ID': payload.userId || '',
      'Plan type': payload.planType || '',
      'Free Campaign': payload.isFreeCampaign || false,
      'Partner ID': getPartnerId(),
    });
  },
  smartCampaignLaunched(payload) {
    const utm = getUtm();
    webengage.track('VS - Smart Campaign Launched', {
      Source: utm.source || '',
      'Device ID': window.localStorage.getItem('deviceId') || '',
      'Ad source': utm.campaign || '',
      'User ID': payload.userId || '',
      'Plan type': payload.planType || '',
      'Free Campaign': payload.isFreeCampaign || false,
      Objective: payload.objective,
      Category: payload.categoryName,
      Budget: String(payload.budget) || '',
      'Campaign Name': payload.name,
      'Partner ID': getPartnerId(),
    });
  },
  smartCampaignAnalysis(payload) {
    const utm = getUtm();
    webengage.track('VS - Smart Campaign Analysis', {
      Source: utm.source || '',
      'Device ID': window.localStorage.getItem('deviceId') || '',
      'Ad source': utm.campaign || '',
      'User ID': payload.userId || '',
      'Plan type': payload.planType || '',
      'Free Campaign': payload.isFreeCampaign || false,
      Objective: payload.objective,
      Category: payload.categoryName,
      Budget: String(payload.budget) || '',
      'Campaign Name': payload.name,
      'Partner ID': getPartnerId(),
    });
  },
  smartCampaignEdited(payload) {
    const utm = getUtm();
    webengage.track('VS - Smart Campaign Edited', {
      Source: utm.source || '',
      'Device ID': window.localStorage.getItem('deviceId') || '',
      'Ad source': utm.campaign || '',
      'User ID': payload.userId || '',
      'Plan type': payload.planType || '',
      'Free Campaign': payload.isFreeCampaign || false,
      Objective: payload.objective,
      Category: payload.categoryName,
      Budget: String(payload.budget) || '',
      'Campaign Name': payload.name,
      'Partner ID': getPartnerId(),
    });
  },
  accountTrackingVisited(payload) {
    const utm = getUtm();
    console.log('VS - account tracking visited', {
      Source: utm.source || '',
      'Device ID': window.localStorage.getItem('deviceId') || '',
      'Ad source': utm.campaign || '',
      'User ID': payload.userId || '',
      'Plan type': payload.planType || '',
      'Reports generated': payload.reportsGenerated || '',
      'Partner ID': getPartnerId(),
      'Source CTA': payload.sourceCTA || '',
    });
    webengage.track('VS - account tracking visited', {
      Source: utm.source || '',
      'Device ID': window.localStorage.getItem('deviceId') || '',
      'Ad source': utm.campaign || '',
      'User ID': payload.userId || '',
      'Plan type': payload.planType || '',
      'Reports generated': payload.reportsGenerated || '',
      'Partner ID': getPartnerId(),
      'Source CTA': payload.sourceCTA || '',
    });
  },
  accountTrackingAddAccount(payload) {
    const utm = getUtm();
    webengage.track('VS - account tracking - Add account clicked', {
      Source: utm.source || '',
      'Device ID': window.localStorage.getItem('deviceId') || '',
      'Ad source': utm.campaign || '',
      'User ID': payload.userId || '',
      'Plan type': payload.planType || '',
      'Partner ID': payload.brandId || '',
    });
  },
  accountTrackingAccountAdded(payload) {
    const utm = getUtm();
    webengage.track('VS - account tracking - Account Added', {
      Source: utm.source || '',
      'Device ID': window.localStorage.getItem('deviceId') || '',
      'Ad source': utm.campaign || '',
      'User ID': payload.userId || '',
      'Plan type': payload.planType || '',
      'Partner ID': payload.brandId || '',
      'Account upload type': payload.accountUploadType || '',
      'Free reports': payload.freeReports,
    });
  },
  accountTrackingStarted(payload) {
    const utm = getUtm();
    webengage.track('VS - account tracking - Tracking Started', {
      Source: utm.source || '',
      'Device ID': window.localStorage.getItem('deviceId') || '',
      'Ad source': utm.campaign || '',
      'User ID': payload.userId || '',
      'Plan type': payload.planType || '',
      'Partner ID': payload.brandId || '',
      'Account upload type': payload.accountUploadType || '',
      'Free reports': payload.freeReports,
      'Reports generated': payload.reportsGenerated || '',
      'Collection name': payload.collectionName || '',
      Platform: payload.platform || '',
    });
  },
  accountTrackingReportShared(payload) {
    const utm = getUtm();
    webengage.track('VS - account tracking - Report Shared', {
      Source: utm.source || '',
      'Device ID': window.localStorage.getItem('deviceId') || '',
      'Ad source': utm.campaign || '',
      'User ID': payload.userId || '',
      'Plan type': payload.planType || '',
      'Partner ID': payload.brandId || '',
      'Account upload type': payload.accountUploadType || '',
      'Collection name': payload.collectionName || '',
      Platform: payload.platform || '',
    });
  },
  accountTrackingUpgradePlanClicked(payload) {
    const utm = getUtm();
    webengage.track('VS - account tracking - Upgrade Now Clicked', {
      Source: utm.source || '',
      'Device ID': window.localStorage.getItem('deviceId') || '',
      'Ad source': utm.campaign || '',
      'User ID': payload.userId || '',
      'Plan type': payload.planType || '',
      'Partner ID': payload.brandId || '',
    });
  },

  topicResearchVisited(payload) {
    const utm = getUtm();
    webengage.track('VS - Content Discovery visited', {
      Source: utm.source || '',
      'Device ID': window.localStorage.getItem('deviceId') || '',
      'Ad source': utm.campaign || '',
      'User ID': payload.userId || '',
      'Plan type': payload.planType || '',
      'Reports generated': payload.reportsGenerated || '',
      'Partner ID': getPartnerId(),
      'Source CTA': payload.sourceCTA || '',
    });
  },
  topicResearchGenerated(payload) {
    const utm = getUtm();
    webengage.track('VS - topic research - Report Generated', {
      Source: utm.source || '',
      'Device ID': window.localStorage.getItem('deviceId') || '',
      'Ad source': utm.campaign || '',
      'User ID': payload.userId || '',
      'Plan type': payload.planType || '',
      'Partner ID': payload.brandId || '',
      Keywords: payload.keywords || '',
      'Date Range': payload.dateRange || '',
      Platform: payload.platform || '',
    });
  },
  topicResearchReportViewed(payload) {
    const utm = getUtm();
    webengage.track('VS - topic research - Report Viewed', {
      Source: utm.source || '',
      'Device ID': window.localStorage.getItem('deviceId') || '',
      'Ad source': utm.campaign || '',
      'User ID': payload.userId || '',
      'Plan type': payload.planType || '',
      'Partner ID': payload.brandId || '',
      Keywords: payload.keywords || '',
      'Date Range': payload.dateRange || '',
      Platform: payload.platform || '',
      'Shared report': payload.isShared ? 'True' : 'False',
    });
  },
  topicResearchUpgradePlanClicked(payload) {
    const utm = getUtm();
    webengage.track('VS - topic research - Upgrade Now Clicked', {
      Source: utm.source || '',
      'Device ID': window.localStorage.getItem('deviceId') || '',
      'Ad source': utm.campaign || '',
      'User ID': payload.userId || '',
      'Plan type': payload.planType || '',
      'Partner ID': payload.brandId || '',
    });
  },
  organisationTypeSelected(payload) {
    const utm = getUtm();
    general.wClickTracking(
      'VS - Organisation Type Selected',
      {
        Source: utm.source || '',
        'Device ID': window.localStorage.getItem('deviceId') || '',
        'Ad source': utm.campaign || '',
        'User ID': payload.userId || '',
        'Plan type': payload.planType || '',
        'Partner ID': getPartnerId(),
        'Organisation Type': payload['Organisation type'],
      },
      ['lens']
    );
  },
  userRoleSelected(payload) {
    const utm = getUtm();
    general.wClickTracking(
      'VS - User Role Selected',
      {
        Source: utm.source || '',
        'Device ID': window.localStorage.getItem('deviceId') || '',
        'Ad source': utm.campaign || '',
        'User ID': payload.userId || '',
        'Plan type': payload.planType || '',
        'Partner ID': getPartnerId(),
        'User Role': payload['User Role'] || '',
      },
      ['lens']
    );
  },
  creatorCountSelected(payload) {
    const utm = getUtm();
    general.wClickTracking(
      'VS - Creator Count Selected',
      {
        Source: utm.source || '',
        'Device ID': window.localStorage.getItem('deviceId') || '',
        'Ad source': utm.campaign || '',
        'User ID': payload.userId || '',
        'Plan type': payload.planType || '',
        'Partner ID': getPartnerId(),
        'Creator Count': payload['Creator Count'] || '',
      },
      ['lens']
    );
  },
  imTimelineSelected(payload) {
    const utm = getUtm();
    general.wClickTracking(
      'VS - IM Timeline Selected',
      {
        Source: utm.source || '',
        'Device ID': window.localStorage.getItem('deviceId') || '',
        'Ad source': utm.campaign || '',
        'User ID': payload.userId || '',
        'Plan type': payload.planType || '',
        'Partner ID': getPartnerId(),
        'IM Timeline': payload['IM Timeline'] || '',
      },
      ['lens']
    );
  },
  requirementSelected(payload) {
    const utm = getUtm();
    general.wClickTracking(
      'VS - Onboarding Requirement Selected',
      {
        Source: utm.source || '',
        'Device ID': window.localStorage.getItem('deviceId') || '',
        'Ad source': utm.campaign || '',
        'User ID': payload.userId || '',
        'Plan type': payload.planType || '',
        'Partner ID': getPartnerId(),
        Requirement: payload['Requirement'],
        'Organisation type': payload['Organisation type'],
        'User Role': payload['User Role'],
        'Creator Count': payload['Creator Count'],
        'IM timeline': payload['IM timeline'],
      },
      ['lens']
    );
  },
  campaignFormVisited(payload) {
    const utm = getUtm();

    general.wClickTracking(
      'VS - Campaign Form Visited',
      {
        Source: utm.source || '',
        'Device ID': window.localStorage.getItem('deviceId') || '',
        'Ad source': utm.campaign || '',
        'User ID': payload.userId || '',
        Objective: payload.objective || '',
        'Partner ID': getPartnerId(),
        'Plan type': payload.planType || '',
        'Source CTA': payload.sourceCTA || '',
      },
      ['lens']
    );
  },
  campaignObjectiveVisited(payload) {
    const utm = getUtm();

    general.wClickTracking(
      'VS - Campaign Objective Page Visited',
      {
        Source: utm.source || '',
        'Device ID': window.localStorage.getItem('deviceId') || '',
        'Ad source': utm.campaign || '',
        'User ID': payload.userId || '',
        'Partner ID': getPartnerId(),
        'Plan type': payload.planType || '',
        'Source CTA': payload.sourceCTA || '',
      },
      ['lens']
    );
  },
  campaignObjectiveSelected(payload) {
    const utm = getUtm();

    general.wClickTracking(
      'VS - Campaign Objective Selected',
      {
        Source: utm.source || '',
        'Device ID': window.localStorage.getItem('deviceId') || '',
        'Ad source': utm.campaign || '',
        'User ID': payload.userId || '',
        Objective: payload.objective || '',
        'Partner ID': getPartnerId(),
        'Plan type': payload.planType || '',
        'Source CTA': payload.sourceCTA || '',
      },
      ['lens']
    );
  },
  campaignFormSubmitted(payload) {
    const utm = getUtm();

    general.wClickTracking(
      'VS - Campaign Form Filled',
      {
        Source: utm.source || '',
        'Device ID': window.localStorage.getItem('deviceId') || '',
        'Ad source': utm.campaign || '',
        'User ID': payload.userId || '',
        'Plan type': payload.planType || '',
        'Partner ID': getPartnerId(),
        'Free Campaign': payload.isFreeCampaign,
        Objective: payload.objective || '',
        Category: payload.category || '',
        Budget: payload.budget || '',
        'Campaign Name': payload.campaignName || '',
      },
      ['lens']
    );
  },
};

export const {
  homePageVisited,
  homePageSearchUsed,
  topNavSearchUsed,
  featuredInfluencersFiltersChanged,
  homePageCreatorCardClicked,
  creatorDiscoveryPageVisited,
  creatorDiscoverySearchUsed,
  leaderboardVisited,
  creatorAddedToCollection,
  creatorCollectionCreated,
  creatorCollectionShared,
  collectionListingPage,
  collectionOpened,
  collectionDetailsUpdated,
  collectionDeleted,
  collectionShare,
  userSignUp,
  userLogsIn,
  upgradePlanClicked,
  leadSubmitted,
  userLogOut,
  creatorProfileVisited,
  creatorProfileShared,
  wClickTracking,
  genreInsightVisited,
  genreUpgradeNow,
  genreFilterApplied,
  campaignReportVisited,
  campaignReportAddPost,
  campaignReportPostAdded,
  campaignReportGenerated,
  campaignReportUpgrade,
  initWebengageUser,
  smartCampaignsVisited,
  createSmartCampaignClicked,
  smartCampaignLaunched,
  smartCampaignAnalysis,
  smartCampaignEdited,
  accountTrackingVisited,
  accountTrackingAddAccount,
  accountTrackingAccountAdded,
  accountTrackingStarted,
  accountTrackingReportShared,
  accountTrackingUpgradePlanClicked,
  topicResearchVisited,
  topicResearchGenerated,
  topicResearchReportViewed,
  topicResearchUpgradePlanClicked,
  organisationTypeSelected,
  userRoleSelected,
  creatorCountSelected,
  imTimelineSelected,
  requirementSelected,
  campaignFormVisited,
  campaignObjectiveVisited,
  campaignObjectiveSelected,
  campaignFormSubmitted,
} = general;
